<template>
  <div class="tag">
    <div class="tag-item cu-p fs-14" @click="select(ind)" :class="[state.tagsIndex == ind ? 'active' : '']"
      v-for="(el, ind) in props.tags" :key="el.value">
      {{ el.label }}
    </div>
  </div>
</template>
<script setup>
import { reactive,defineEmits } from "vue";
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
const props = defineProps({
  tags: {
    //当前合同接收方数据
    type: Array,
    default() {
      return getXialaListdata2(['今日', '本月', '本年度'], ['1', '2', '3']);
    },
  },
})
const state = reactive({
  tagsIndex: 0,
})
const select = (index) => {
  state.tagsIndex = index
  emit('selectEmit',props.tags[index])
};
const emit = defineEmits(['selectEmit'])
</script>
<style lang="scss" scoped>
// @import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

.tag {
  display: flex;

  &-item {
    padding: 4px 24px;
    background-color: #f4f5f9;
    color: #999;
    margin-left: 9px;
    border-radius: 4px;
  }

  .active {
    color: $color-button;
    background-color: $active-theme;
  }
}
</style>