<template>
	<div class="statistics autobox2">
		<div class="statistics-title mb-20">
			<div class="fs-20 fw-b">合同签署统计</div>
			<tags @selectEmit="getCountData($event.value)" />
		</div>
		<count class="mb-20" :counts="state.counts" v-loading="countLoading" :element-loading-text="config.loading.text"
			:element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"/>
		<div class="statistics-title mb-20">
			<div class="fs-20 fw-b">文件列表</div>
		</div>
		<nodata style="margin-top: 90px;" title="当前暂无待签署文件～" v-show="state.Notice.length==0"/>
		<el-table v-show="state.Notice.length!=0" :data="state.Notice" class="mb-20"
			:header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
			row-key="id" @selection-change="selectionChange">
			<!-- <el-table-column :align="'center'" prop="contractType" label="合同类型" /> -->
			<!-- <el-table-column :align="'center'" prop="initiatorStr" label="发起方" /> -->
			<el-table-column :align="'center'" prop="sponsorStr" label="发起人" />
			<el-table-column :align="'center'" prop="showSignatory" label="签署方" width="180" />
			<el-table-column :align="'center'" prop="initiateData" label="发起日期" />
			<el-table-column :align="'center'" prop="contractName" label="合同标题" />
			<!-- <el-table-column :align="'center'" prop="fileCount" label="文件数量" /> -->
			<el-table-column :align="'center'" label="当前状态">
				<template #default="scope">
					<div class="dp-fc contractStatus" :class="'contractStatus' + scope.row.contractStatus">
						<div class="w-70 ta-l">{{ getcontractStatus(scope.row.contractStatus) }}</div>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column :align="'center'" prop="contractDeadline" label="截止日期" /> -->
		</el-table>
	</div>
	<Detailsdrawer ref="detailsRef" @withdrawEmit="getDataList()"></Detailsdrawer>
</template>

<script setup>
import { ref, unref, reactive } from "vue";
import count from '../../../components/count/count.vue'
import Detailsdrawer from "@/views/contractDocuments/components/details.vue"//详情
import tags from "@/views/home/components/tool/tags.vue"
import nodata from "@/components/layout/nodata.vue"
import { getcontractStatus } from "@/utils/server/getcode.js"
import { queryResourceTable } from "@/api/cancelToken.js"
import { httpToken } from "@/utils/request";
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
import { add } from "@/utils/server/format"
import qs from "qs";
const loading = ref(false);//loading 显示
const countLoading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const detailsRef = ref();//详情弹框ref
const state = reactive({
	counts: getXialaListdata2(['全部文件', '已完成数', '未完成数'], [0, 0, 0]),
	Notice: []
});
// 获取统计数据
const getCountData = (type) => {
	countLoading.value = true
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/signCount',
		data: qs.stringify({
			type: type
		}),
	}).then(res => {
		state.counts[0].value = res.data.count
		state.counts[1].value = res.data.accomplish
		state.counts[2].value = add(res.data.notHaveAccomplish, res.data.loseEfficacy)
		countLoading.value = false;
	}).catch((err) => {
		countLoading.value = false;
	})
}
getCountData(1)
// 展示详情数据
const showDetail = (row) => {
	unref(detailsRef).getDetails(row)
}
const getDataList = (val = 1) => {
	currentPage.value = val;
	state.Notice = []
	loading.value = true
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/list',
		data: qs.stringify({
			page: val,
			rows: 20
		}),
	}).then((res) => {
		// 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
		if (val !== res.data.current) {
			getDataList(res.data.current)
			return
		}
		totalPage.value = res.data.total;
		res.data.records.forEach(item => {
			item.showSignatory = ''
			item.contractSignRecords.forEach((item2, ind2) => {
				item.initiatorStr = item.initiatorStr ? item.initiatorStr : '/'
				item.showSignatory += ind2 == 0 ? '' : ','
				item.showSignatory += item2.signerType == 1 ? item2.contractSignPersonList[0].companyName : item2.contractSignPersonList[0].signerName
			})
		});
		state.Notice = res.data.records
		loading.value = false;
	}).catch((err) => {
		loading.value = false;
	})
}
getDataList()
</script>

<style lang="scss" scoped>
// @import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

.statistics {
	background-color: #fff;
	width: calc(50% - 6px);
	padding: 20px 30px;

	&-title {
		display: flex;
		justify-content: space-between;
	}
}

::v-deep .contractStatus {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
}

::v-deep .contractStatus0 {
	color: #1890FF;
}

::v-deep .contractStatus1 {
	color: #52C41A;
}

::v-deep .contractStatus2 {
	color: #FAAD14;
}
</style>